import myImage from './josh.png';
import React, { useEffect, useRef, useState } from 'react';

function AnimatedImage() {
  const imageRef = useRef(null);
  const [showText, setShowText] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);

  /*** Configurable Parameters ***/
  const animationDuration = 6000; // Animation duration in milliseconds
  const initialScale = 0.1;       // Starting scale (e.g., 0.1 for 10% of original size)
  const finalScaleFactor = 500;   // Final scale factor (e.g., 300 times the screen size)
  const initialRotation = 0;      // Starting rotation in degrees
  const finalRotation = 360 * 10; // Ending rotation in degrees
  const overlayMessage = "Happy Birthday Rat!"; // Text to display after animation
  /*******************************/


  const calculateAge = () => {
    const birthDate = new Date('November 7, 1994 00:00:00');
    const now = new Date();

    // Calculate the total difference in milliseconds
    let diff = now - birthDate;

    // Calculate years
    let years = now.getFullYear() - birthDate.getFullYear();

    // Adjust for the month and day
    let months = now.getMonth() - birthDate.getMonth();
    if (months < 0) {
      years--;
      months += 12;
    }

    let days = now.getDate() - birthDate.getDate();
    if (days < 0) {
      months--;
      // Get the number of days in the previous month
      const prevMonth = new Date(now.getFullYear(), now.getMonth(), 0).getDate();
      days += prevMonth;
      if (months < 0) {
        years--;
        months += 12;
      }
    }

    const hours = now.getHours();
    const minutes = now.getMinutes();

    // Format the age string
    const ageString = `${years} years, ${months} months, ${days} days, ${hours} hours, and ${minutes} minutes`;

    return(ageString);
  };


  useEffect(() => {
    if (imageLoaded) {
      const image = imageRef.current;

      // Get screen dimensions
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      // Calculate the maximum scale based on screen size
      const maxDimension = Math.max(screenWidth, screenHeight);
      const finalScale = finalScaleFactor * (maxDimension / image.naturalWidth);

      // Total animation frames based on duration and frame rate (approx. 60fps)
      const totalFrames = Math.round((animationDuration / 1000) * 60);
      let frame = 0;

      const animate = () => {
        if (frame <= totalFrames) {
          // Calculate progress ratio (0 to 1)
          const progress = frame / totalFrames;

          // Easing function (optional): ease-in-out
          const easedProgress = 0.5 * (1 - Math.cos(Math.PI * progress));

          // Update scale and rotation based on eased progress
          const scale = initialScale + easedProgress * (finalScale - initialScale);
          const rotation = initialRotation + easedProgress * (finalRotation - initialRotation);

          image.style.transform = `translate(-50%, -50%) scale(${scale}) rotate(${rotation}deg)`;
          frame++;
          requestAnimationFrame(animate);
        } else {
          // Animation complete
          setShowText(true); // Display the overlay text
        }
      };

      // Start the animation
      animate();
    }
  }, [
    imageLoaded,
    animationDuration,
    finalScaleFactor,
    finalRotation,
    initialRotation,
    initialScale,
  ]);

  // Inline styles for the container, image, and overlay text
  const containerStyle = {
    position: 'fixed',
    top: '50%',
    left: '50%',
  };

  const imageStyle = {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '1px', // Start with a very small size
    height: 'auto',
    transformOrigin: 'center',
  };

  const overlayStyle = {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: showText ? 'flex' : 'none',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    color: '#ffffff',
    fontSize: '3rem',
    zIndex: 1, // Ensure the overlay appears above other elements
    textAlign: 'center',
  };

  return (
    <>
      <div style={containerStyle}>
        <img
          ref={imageRef}
          src={myImage}
          alt="Animated"
          style={imageStyle}
          onLoad={() => setImageLoaded(true)}
        />
      </div>
      <div style={overlayStyle}>
        <p>{overlayMessage}</p>
        <p style={{ fontSize: 18 }}>
          You are officially {calculateAge()} old.
        </p>
      </div>
      <div
        style={{
          fontSize: 10,
          position: 'fixed',
          bottom: 10,
          textAlign: 'center',
          width: '100%',
          display: showText ? 'block' : 'none',
        }}
      >
        <p>
          To any future employers, Josh Sudell would be a great addition to any
          team or role you are considering him for.
        </p>
      </div>
    </>
  );
}

export default AnimatedImage;

